export const wordLists = {
  "fi-de": [
    {
      name: "adjective",
      list: {
        gut: "hyvä",
        schlecht: "huono",
        neu: "uusi",
        alt: "vanha",
        jung: "nuori",
        groß: "iso",
        klein: "pieni",
        schön: "kaunis",
        billig: "halpa",
        teuer: "kallis",
        dunkel: "tumma",
        hell: "valoisa",
        zusammen: "yhdessä",
        getrennt: "erikseen",
        wichtig: "tärkeä",
        müde: "väsynyt",
      },
    },
    {
      name: "color",
      list: {
        weiß: "valkoinen",
        schwarz: "musta",
        rot: "punainen",
        blau: "sininen",
        grün: "vihreä",
        gelb: "keltainen",
        bunt: "värikäs",
      },
    },
    {
      name: "communication",
      list: {
        "Hallo!": "Hei!",
        "Guten Tag!": "Hei!",
        "Tschüß!": "Näkemiin!",
        "Danke!": "Kiitos!",
        "Bitte!": "Ole hyvä!",
        "Entschuldigung!": "Anteeksi!",
        "Stopp!": "Seis!",
        "Vorsicht!": "Varo!",
        ja: "kyllä",
        nein: "ei",
      },
    },
    {
      name: "food",
      list: {
        "das Essen": "ruoka",
        "das Restaurant": "ravintola",
        "das Café": "kahvila",
        "die Bar": "baari",
        "die Speisekarte": "ruokalista",
        "die Vorspeise": "alkupala",
        "die Hauptspeise": "pääruoka",
        "der Nachtisch": "jälkiruoka",
        "die Rechnung": "lasku",
        vegetarisch: "kasvissyöjä",
        vegan: "vegaani",
        glutenfrei: "gluteeniton",
        "der Fisch": "kala",
        "das Fleisch": "liha",
        "der Salat": "salaatti",
        "die Milch": "maito",
        "der Käse": "juusto",
        "das Obst": "hedelmät",
        "das Gemüse": "vihannekset",
        "die Nüsse": "pähkinät",
        "der Kaffee": "kahvi",
        "der Tee": "tee",
        "das Wasser": "vesi",
        "der Wein": "viini",
        "das Bier": "olut",
        alkoholfrei: "alkoholiton",
        "Prost!": "Kippis!",
      },
    },
    {
      name: "health",
      list: {
        "der Arzt / die Ärztin": "lääkäri",
        "das Krankenhaus": "sairaala",
        "die Apotheke": "apteekki",
        "die Schmerzmittel": "särkylääke",
        "das Medikament": "lääke",
        "die Schmerzen": "kipu",
        "der Rücken": "selkä",
        "der Fuß": "jalka",
        "der Bauch": "vatsa",
        "das Bein": "jalka",
        "der Kopf": "pää",
        "die Hand": "käsi",
        "der Arm": "käsivarsi",
      },
    },
    {
      name: "numbers",
      list: {
        eins: "yksi",
        zwei: "kaksi",
        drei: "kolme",
        vier: "neljä",
        fünf: "viisi",
        sechs: "kuusi",
        sieben: "seitsemän",
        acht: "kahdeksan",
        neun: "yhdeksän",
        zehn: "kymmenen",
        zwanzig: "kaksikymmentä",
        fünfzig: "viisikymmentä",
        hundert: "sata",
        tausend: "tuhat",
      },
    },
    {
      name: "orientation",
      list: {
        "die Richtung": "suunta",
        "der Eingang": "sisäänkäynti",
        "der Ausgang": "uloskäynti",
        "die Straße": "katu",
        "der Weg": "tie",
        "der Platz": "tori",
        rechts: "oikealla",
        links: "vasemmalla",
        geradeaus: "suoraan eteenpäin",
        zurück: "takaisin",
      },
    },
    {
      name: "personal-information",
      list: {
        "der Vorname": "etunimi",
        "der Nachname": "sukunimi",
        "die Adresse": "osoite",
        "das Land": "maa",
        "der Geburtsort": "syntymäpaikka",
        "das Geburtsdatum": "syntymäaika",
        "der Beruf": "ammatti",
        "der Mann": "mies",
        "die Frau": "nainen",
        "der / die Erwachsene": "aikuinen",
        "das Kind": "lapsi",
      },
    },
    {
      name: "sentences",
      list: {
        "Was ist…?": "Mikä ...?",
        "Wo ist …?": "Missä on ...?",
        "Wie …?": "Kuinka …?",
        "Wie viel kostet es?": "Kuinka paljon se on?",
        "Wie lange dauert es?": "Kauanko se kestää?",
        "Gibt es …?": "On ...?",
        "Ich hätte gerne …": "Haluaisin ...",
        "Ich brauche …": "Tarvitsen ...",
        "Kein Problem!": "Ei ongelmaa!",
      },
    },
    {
      name: "shopping",
      list: {
        "der Supermarkt": "supermarketti",
        "das Geld": "raha",
        "die Karte (Bankkarte)": "pankkikortti",
        "die Tasche": "laukku",
        "der Tampon": "tamponi",
        "die Binde": "terveysside",
        "das Kondom": "kondomi",
        "die Zahnbürste": "hammasharja",
        "die Hilfe": "apua",
        "die Polizei": "poliisi",
      },
    },
    {
      name: "surrounding",
      list: {
        "das Haus": "talo",
        "die Wohnung": "asunto",
        "das Hotel": "hotelli",
        "das Museum": "museo",
        "der Strand": "ranta",
        "der Wald": "metsä",
        "der Berg": "vuori",
        "der Park": "puisto",
        "der Fluss": "joki",
        "das Meer": "meri",
        "der See": "järvi",
        "das Wetter": "sää",
        "die Sonne": "aurinko",
        "der Regen": "sade",
        "der Schnee": "lumi",
        "das Gewitter": "ukkonen",
      },
    },
    {
      name: "technology",
      list: {
        "das Internet": "internet",
        "das Passwort": "salasana",
        "der Computer": "tietokone",
        "die Steckdose": "pistorasia",
        aufladen: "ladata",
      },
    },
    {
      name: "time",
      list: {
        Zeit: "aika",
        "der Tag": "päivä",
        "die Woche": "viikko",
        "der Monat": "kuukausi",
        "das Jahr": "vuosi",
        Montag: "maanantai",
        Dienstag: "tiistai",
        Mittwoch: "keskiviikko",
        Donnerstag: "torstai",
        Freitag: "perjantai",
        Samstag: "lauantai",
        Sonntag: "sunnuntai",
        morgens: "aamu",
        nachmittags: "iltapäivä",
        abends: "illalla",
        nachts: "yöllä",
        früher: "aiemmin",
        später: "myöhemmin",
        pünktlich: "ajallaan",
        verspätet: "viivästynyt",
      },
    },
    {
      name: "transport",
      list: {
        "der Zug": "juna",
        "der Bus": "bussi",
        "das Auto": "auto",
        "die Straßenbahn": "raitiovaunu",
        "das Taxi": "taksi",
        "das Fahrrad": "polkupyörä",
        "zu Fuß": "jalkaisin",
        "der Bahnhof": "rautatieasema",
        "der Busbahnhof": "linja-autoasema",
        "die Haltestelle": "pysäkki",
      },
    },
    {
      name: "verb",
      list: {
        arbeiten: "työskennellä",
        besuchen: "käydä",
        bleiben: "jäädä",
        brauchen: "tarvita",
        essen: "syödä",
        fragen: "kysyä",
        geben: "antaa",
        gehen: "kävellä",
        haben: "omistaa",
        kaufen: "ostaa",
        können: "voida",
        lernen: "oppia",
        machen: "tehdä",
        nehmen: "ottaa",
        sagen: "sanoa",
        schreiben: "kirjoittaa",
        sehen: "nähdä",
        sein: "olla",
        suchen: "etsiä",
        telefonieren: "soittaa",
        trinken: "juoda",
        wissen: "tietää",
        wollen: "haluta",
        zahlen: "maksaa",
      },
    },
  ],
  "fi-en": [
    {
      name: "adjective",
      list: {
        good: "hyvä",
        bad: "huono",
        new: "uusi",
        old: "vanha",
        young: "nuori",
        big: "iso",
        small: "pieni",
        beautiful: "kaunis",
        cheap: "halpa",
        expensive: "kallis",
        dark: "tumma",
        bright: "valoisa",
        together: "yhdessä",
        separate: "erikseen",
        important: "tärkeä",
        tired: "väsynyt",
      },
    },
    {
      name: "color",
      list: {
        color: "väri",
        white: "valkoinen",
        black: "musta",
        red: "punainen",
        blue: "sininen",
        green: "vihreä",
        yellow: "keltainen",
        colorful: "värikäs",
      },
    },
    {
      name: "communication",
      list: {
        "Hi!": "Hei!",
        "Hello!": "Hei!",
        "Goodbye! / Bye!": "Näkemiin!",
        "Thank you!": "Kiitos!",
        "Please!": "Ole hyvä!",
        "Sorry!": "Anteeksi!",
        "Stop!": "Seis!",
        "Caution!": "Varo!",
        yes: "kyllä",
        no: "ei",
      },
    },
    {
      name: "food",
      list: {
        food: "ruoka",
        restaurant: "ravintola",
        café: "kahvila",
        bar: "baari",
        menu: "ruokalista",
        starter: "alkupala",
        "main course": "pääruoka",
        dessert: "jälkiruoka",
        bill: "lasku",
        vegetarian: "kasvissyöjä",
        vegan: "vegaani",
        "gluten-free": "gluteeniton",
        fish: "kala",
        meat: "liha",
        salad: "salaatti",
        milk: "maito",
        cheese: "juusto",
        fruits: "hedelmät",
        vegetables: "vihannekset",
        nuts: "pähkinät",
        coffee: "kahvi",
        tea: "tee",
        water: "vesi",
        wine: "viini",
        beer: "olut",
        "non-alcoholic": "alkoholiton",
        "Cheers!": "Kippis!",
      },
    },
    {
      name: "health",
      list: {
        doctor: "lääkäri",
        hospital: "sairaala",
        pharmacy: "apteekki",
        painkiller: "särkylääke",
        medicine: "lääke",
        pain: "kipu",
        back: "selkä",
        foot: "jalka",
        stomach: "vatsa",
        leg: "jalka",
        head: "pää",
        hand: "käsi",
        arm: "käsivarsi",
      },
    },
    {
      name: "numbers",
      list: {
        one: "yksi",
        two: "kaksi",
        three: "kolme",
        four: "neljä",
        five: "viisi",
        six: "kuusi",
        seven: "seitsemän",
        eight: "kahdeksan",
        nine: "yhdeksän",
        ten: "kymmenen",
        twenty: "kaksikymmentä",
        fifty: "viisikymmentä",
        hundred: "sata",
        thousand: "tuhat",
      },
    },
    {
      name: "orientation",
      list: {
        direction: "suunta",
        entrance: "sisäänkäynti",
        exit: "uloskäynti",
        street: "katu",
        way: "tie",
        square: "tori",
        right: "oikealla",
        left: "vasemmalla",
        "straight ahead": "suoraan eteenpäin",
        back: "takaisin",
      },
    },
    {
      name: "personal-information",
      list: {
        "first name": "etunimi",
        "last name": "sukunimi",
        address: "osoite",
        country: "maa",
        "place of birth": "syntymäpaikka",
        "date of birth": "syntymäaika",
        "profession, job": "ammatti",
        man: "mies",
        woman: "nainen",
        adult: "aikuinen",
        child: "lapsi",
      },
    },
    {
      name: "sentences",
      list: {
        "What is …?": "Mikä ...?",
        "Where is …?": "Missä on ...?",
        "How …?": "Kuinka …?",
        "How much is it?": "Kuinka paljon se on?",
        "How long does it take?": "Kauanko se kestää?",
        "Is there …?": "On ...?",
        "I would like ...": "Haluaisin ...",
        "I need ...": "Tarvitsen ...",
        "No problem!": "Ei ongelmaa!",
      },
    },
    {
      name: "shopping",
      list: {
        supermarket: "supermarketti",
        cash: "raha",
        "bank card": "pankkikortti",
        bag: "laukku",
        tampon: "tamponi",
        "(sanitary) pad": "terveysside",
        condom: "kondomi",
        toothbrush: "hammasharja",
        help: "apua",
        police: "poliisi",
      },
    },
    {
      name: "surrounding",
      list: {
        house: "talo",
        apartment: "asunto",
        hotel: "hotelli",
        museum: "museo",
        beach: "ranta",
        forest: "metsä",
        mountain: "vuori",
        park: "puisto",
        river: "joki",
        sea: "meri",
        lake: "järvi",
        weather: "sää",
        sun: "aurinko",
        rain: "sade",
        snow: "lumi",
        thunderstorm: "ukkonen",
      },
    },
    {
      name: "technology",
      list: {
        internet: "internet",
        password: "salasana",
        computer: "tietokone",
        socket: "pistorasia",
        "to recharge": "ladata",
      },
    },
    {
      name: "time",
      list: {
        time: "aika",
        day: "päivä",
        week: "viikko",
        month: "kuukausi",
        year: "vuosi",
        Monday: "maanantai",
        Tuesday: "tiistai",
        Wednesday: "keskiviikko",
        Thursday: "torstai",
        Friday: "perjantai",
        Saturday: "lauantai",
        Sunday: "sunnuntai",
        "in the morning": "aamu",
        "in the afternoon": "iltapäivä",
        "in the evening": "illalla",
        "at night": "yöllä",
        earlier: "aiemmin",
        later: "myöhemmin",
        "on time": "ajallaan",
        delayed: "viivästynyt",
      },
    },
    {
      name: "transport",
      list: {
        train: "juna",
        bus: "bussi",
        car: "auto",
        tram: "raitiovaunu",
        "taxi, cab": "taksi",
        bicycle: "polkupyörä",
        "on foot": "jalkaisin",
        "train station": "rautatieasema",
        "bus station": "linja-autoasema",
        stop: "pysäkki",
      },
    },
    {
      name: "verb",
      list: {
        "to work": "työskennellä",
        "to visit": "käydä",
        "to stay": "jäädä",
        "to need": "tarvita",
        "to eat": "syödä",
        "to ask": "kysyä",
        "to give": "antaa",
        "to go": "kävellä",
        "to have": "omistaa",
        "to buy": "ostaa",
        "(I/you/we/they) can": "voida",
        "to learn": "oppia",
        "to make": "tehdä",
        "to take": "ottaa",
        "to say": "sanoa",
        "to write": "kirjoittaa",
        "to see": "nähdä",
        "to be": "olla",
        "to search": "etsiä",
        "to call": "soittaa",
        "to drink": "juoda",
        "to know": "tietää",
        "to want": "haluta",
        "to pay": "maksaa",
      },
    },
  ],
  "fi-es": [
    {
      name: "adjective",
      list: {
        "bueno/a": "hyvä",
        "malo/a": "huono",
        "nuevo/a": "uusi",
        "viejo/a": "vanha",
        joven: "nuori",
        grande: "iso",
        "pequeño/a": "pieni",
        "bonito/a": "kaunis",
        "barato/a": "halpa",
        "caro/a": "kallis",
        "claro/a": "tumma",
        brillante: "valoisa",
        "juntos/as": "yhdessä",
        "separado/a": "erikseen",
        importante: "tärkeä",
        "cansado/a": "väsynyt",
      },
    },
    {
      name: "color",
      list: {
        "el color": "väri",
        "blanco/a": "valkoinen",
        "negro/a": "musta",
        "rojo/a": "punainen",
        azul: "sininen",
        verde: "vihreä",
        "amarillo/a": "keltainen",
        multicolor: "värikäs",
      },
    },
    {
      name: "communication",
      list: {
        "¡Hola!": "Hei!",
        "¡Adiós!": "Näkemiin!",
        "¡Gracias!": "Kiitos!",
        "¡Por favor!": "Ole hyvä!",
        "¡Disculpe!": "Anteeksi!",
        "¡Alto!": "Seis!",
        "¡Cuidado!": "Varo!",
        sí: "kyllä",
        no: "ei",
      },
    },
    {
      name: "food",
      list: {
        "la comida": "ruoka",
        "el restaurante": "ravintola",
        "el café": "kahvi",
        "el bar": "baari",
        "el menú": "ruokalista",
        "el aperitivo": "alkupala",
        "el plato principal": "pääruoka",
        "el postre": "jälkiruoka",
        "la cuenta": "lasku",
        vegetariano: "kasvissyöjä",
        vegano: "vegaani",
        "sin gluten": "gluteeniton",
        "el pescado": "kala",
        "la carne": "liha",
        "el ensalada": "salaatti",
        "la leche": "maito",
        "el queso": "juusto",
        "las frutas": "hedelmät",
        "las verduras": "vihannekset",
        "los frutos secos": "pähkinät",
        "el té": "tee",
        "la agua": "vesi",
        "el vino": "viini",
        "la cerveza": "olut",
        "sin alcohol": "alkoholiton",
        "¡Salud!": "Kippis!",
      },
    },
    {
      name: "health",
      list: {
        "el/la médico/a": "lääkäri",
        "el hospital": "sairaala",
        "la farmacia": "apteekki",
        "el analgésico": "särkylääke",
        "el medicamento": "lääke",
        "el dolor": "kipu",
        "la espalda": "selkä",
        "el pie": "jalka",
        "el vientre": "vatsa",
        "la pierna": "jalka",
        "la cabeza": "pää",
        "el mano": "käsi",
        "el brazo": "käsivarsi",
      },
    },
    {
      name: "numbers",
      list: {
        un: "yksi",
        dos: "kaksi",
        tres: "kolme",
        cuatro: "neljä",
        cinco: "viisi",
        seis: "kuusi",
        siete: "seitsemän",
        ocho: "kahdeksan",
        nueve: "yhdeksän",
        diez: "kymmenen",
        veinte: "kaksikymmentä",
        cincuenta: "viisikymmentä",
        ciento: "sata",
        mil: "tuhat",
      },
    },
    {
      name: "orientation",
      list: {
        "la dirección": "suunta",
        "la entrada": "sisäänkäynti",
        "la salida": "uloskäynti",
        "la calle": "katu",
        "el camino": "tie",
        "la plaza": "tori",
        "(a) la derecha": "oikealla",
        "(a) la izquierda": "vasemmalla",
        seguido: "suoraan eteenpäin",
        "de vuelta": "takaisin",
      },
    },
    {
      name: "personal-information",
      list: {
        nombre: "etunimi",
        apellido: "sukunimi",
        "la dirección": "osoite",
        "el país": "maa",
        "el lugar de nacimiento": "syntymäpaikka",
        "la fecha de nacimiento": "syntymäaika",
        "la profesión": "ammatti",
        "el hombre": "mies",
        "la mujer": "nainen",
        "el adulto": "aikuinen",
        "el niño": "lapsi",
      },
    },
    {
      name: "sentences",
      list: {
        "¿Qué es ...?": "Mikä ...?",
        "¿Dónde está ...?": "Missä on ...?",
        "¿Cómo …?": "Kuinka …?",
        "¿Cuánto cuesta?": "Kuinka paljon se on?",
        "¿Cuánto se tarda?": "Kauanko se kestää?",
        "¿Hay ...?": "On ...?",
        "Me gustaría...": "Haluaisin ...",
        "Necesito...": "Tarvitsen ...",
        "No hay problema.": "Ei ongelmaa!",
      },
    },
    {
      name: "shopping",
      list: {
        "el supermercado": "supermarketti",
        "el dinero": "raha",
        "la tarjeta bancaria": "pankkikortti",
        "el bolso": "laukku",
        "el tampón": "tamponi",
        "la compresa": "terveysside",
        "el preservativo": "kondomi",
        "el cepillo de dientes": "hammasharja",
        "la ayuda": "apua",
        "la policía": "poliisi",
      },
    },
    {
      name: "surrounding",
      list: {
        "la casa": "talo",
        "el apartamento": "asunto",
        "el hotel": "hotelli",
        "el museo": "museo",
        "la playa": "ranta",
        "el bosque": "metsä",
        "la montaña": "vuori",
        "el parque": "puisto",
        "el río": "joki",
        "el mar": "meri",
        "el lago": "järvi",
        "el tiempo": "sää",
        "el sol": "aurinko",
        "la lluvia": "sade",
        "el nieve": "lumi",
        "la tormenta": "ukkonen",
      },
    },
    {
      name: "technology",
      list: {
        "el internet": "internet",
        "la contraseña": "salasana",
        "el ordenador": "tietokone",
        "el enchufe": "pistorasia",
        recargar: "ladata",
      },
    },
    {
      name: "time",
      list: {
        "el tiempo": "aika",
        "el día": "päivä",
        "la semana": "viikko",
        "el mes": "kuukausi",
        "el año": "vuosi",
        lunes: "maanantai",
        martes: "tiistai",
        miércoles: "keskiviikko",
        jueves: "torstai",
        viernes: "perjantai",
        sábado: "lauantai",
        domingo: "sunnuntai",
        "por la mañana": "aamu",
        "por la tarde": "illalla",
        "por la noche": "yöllä",
        antes: "aiemmin",
        "más tarde": "myöhemmin",
        "a tiempo": "ajallaan",
        "con retraso": "viivästynyt",
      },
    },
    {
      name: "transport",
      list: {
        "el tren": "juna",
        "el autobús": "bussi",
        "el coche": "auto",
        "el tranvía": "raitiovaunu",
        "el taxi": "taksi",
        "la bicicleta": "polkupyörä",
        "a pie": "jalkaisin",
        "estación de tren": "rautatieasema",
        "estación de autobuses": "linja-autoasema",
        "parada de autobús": "pysäkki",
      },
    },
    {
      name: "verb",
      list: {
        trabajar: "työskennellä",
        visitar: "käydä",
        quedarse: "jäädä",
        necesitar: "tarvita",
        comer: "syödä",
        preguntar: "kysyä",
        dar: "antaa",
        ir: "kävellä",
        tener: "omistaa",
        comprar: "ostaa",
        poder: "voida",
        aprender: "oppia",
        hacer: "tehdä",
        tomar: "ottaa",
        decir: "sanoa",
        escribir: "kirjoittaa",
        ver: "nähdä",
        ser: "olla",
        buscar: "etsiä",
        "llamar por teléfono": "soittaa",
        beber: "juoda",
        saber: "tietää",
        querer: "haluta",
        pagar: "maksaa",
      },
    },
  ],
  "fi-fr": [
    {
      name: "adjective",
      list: {
        "bon(ne)": "hyvä",
        "mauvais(e)": "huono",
        "nouveau/nouvelle": "uusi",
        "vieux/vieille": "vanha",
        jeune: "nuori",
        "grand(e)": "iso",
        "petit(e)": "pieni",
        "beau/belle": "kaunis",
        "pas cher/chère": "halpa",
        "cher/chère": "kallis",
        sombre: "tumma",
        "clair(e)": "valoisa",
        ensemble: "yhdessä",
        "séparé(e)": "erikseen",
        "important(e)": "tärkeä",
        "fatigué(e)": "väsynyt",
      },
    },
    {
      name: "color",
      list: {
        "la couleur": "väri",
        "blanc/blanche": "valkoinen",
        "noir(e)": "musta",
        rouge: "punainen",
        "bleu(e)": "sininen",
        "vert(e)": "vihreä",
        jaune: "keltainen",
        "de toutes les couleurs": "värikäs",
      },
    },
    {
      name: "communication",
      list: {
        "Salut !": "Hei!",
        "Bonjour !": "Hei!",
        "Au revoir !": "Näkemiin!",
        "Merci !": "Kiitos!",
        "S'il vous plaît ! / S'il te plaît !": "Ole hyvä!",
        "Pardon !": "Anteeksi!",
        "Arrête !": "Seis!",
        "Attention !": "Varo!",
        oui: "kyllä",
        non: "ei",
      },
    },
    {
      name: "food",
      list: {
        "le repas": "ruoka",
        "le restaurant": "ravintola",
        "le café": "kahvi",
        "le bar": "baari",
        "le menu": "ruokalista",
        "l’entrée": "alkupala",
        "le plat principal": "pääruoka",
        "le dessert": "jälkiruoka",
        "l’addition": "lasku",
        "végétarien(ne)": "kasvissyöjä",
        végan: "vegaani",
        "sans gluten": "gluteeniton",
        "le poisson": "kala",
        "la viande": "liha",
        "la salade": "salaatti",
        "le lait": "maito",
        "le fromage": "juusto",
        "les fruits": "hedelmät",
        "les légumes": "vihannekset",
        "les noix": "pähkinät",
        "le thé": "tee",
        "l’eau": "vesi",
        "le vin": "viini",
        "la bière": "olut",
        "sans alcool": "alkoholiton",
        "À ta santé !": "Kippis!",
      },
    },
    {
      name: "health",
      list: {
        "le/la médecin": "lääkäri",
        "l’hôpital": "sairaala",
        "la pharmacie": "apteekki",
        "l’antidouleur": "särkylääke",
        "le médicament": "lääke",
        "la douleur": "kipu",
        "le dos": "selkä",
        "le pied": "jalka",
        "l’abdomen": "vatsa",
        "la jambe": "jalka",
        "la tête": "pää",
        "la main": "käsi",
        "le bras": "käsivarsi",
      },
    },
    {
      name: "numbers",
      list: {
        un: "yksi",
        deux: "kaksi",
        trois: "kolme",
        quatre: "neljä",
        cinq: "viisi",
        six: "kuusi",
        sept: "seitsemän",
        huit: "kahdeksan",
        neuf: "yhdeksän",
        dix: "kymmenen",
        vingt: "kaksikymmentä",
        cinquante: "viisikymmentä",
        cent: "sata",
        mille: "tuhat",
      },
    },
    {
      name: "orientation",
      list: {
        "la direction": "suunta",
        "l’entrée (f.)": "sisäänkäynti",
        "la sortie": "uloskäynti",
        "la rue": "katu",
        "le chemin": "tie",
        "la place": "tori",
        "à droite": "oikealla",
        "à gauche": "vasemmalla",
        "tout droit": "suoraan eteenpäin",
        retour: "takaisin",
      },
    },
    {
      name: "personal-information",
      list: {
        "le prénom": "etunimi",
        "le nom de famille": "sukunimi",
        "l’adresse": "osoite",
        "le pays": "maa",
        "le lieu de naissance": "syntymäpaikka",
        "la date de naissance": "syntymäaika",
        "la profession": "ammatti",
        "l’homme": "mies",
        "la femme": "nainen",
        "l’adulte": "aikuinen",
        "l’enfant": "lapsi",
      },
    },
    {
      name: "sentences",
      list: {
        "Qu’est-ce que c’est ... ?": "Mikä ...?",
        "Où est ... ?": "Missä on ...?",
        "Comment …?": "Kuinka …?",
        "Ça coûte combien ?": "Kuinka paljon se on?",
        "Ça prendra combien de temps ?": "Kauanko se kestää?",
        "Il y a ... ?": "On ...?",
        "Je voudrais ...": "Haluaisin ...",
        "J'ai besoin de ...": "Tarvitsen ...",
        "Pas de problème !": "Ei ongelmaa!",
      },
    },
    {
      name: "shopping",
      list: {
        "le supermarché": "supermarketti",
        "l’argent": "raha",
        "la carte bancaire": "pankkikortti",
        "le sac": "laukku",
        "le tampon": "tamponi",
        "la serviette hygiénique": "terveysside",
        "le préservatif": "kondomi",
        "la brosse à dents": "hammasharja",
        "l’aide": "apua",
        "la police": "poliisi",
      },
    },
    {
      name: "surrounding",
      list: {
        "la maison": "talo",
        "l’appartement": "asunto",
        "l’hôtel": "hotelli",
        "le musée": "museo",
        "la plage": "ranta",
        "le forêt": "metsä",
        "la montagne": "vuori",
        "le parc": "puisto",
        "la rivière": "joki",
        "la mer": "meri",
        "le lac": "järvi",
        "le temps": "sää",
        "le soleil": "aurinko",
        "la pluie": "sade",
        "la neige": "lumi",
        "l’orage": "ukkonen",
      },
    },
    {
      name: "technology",
      list: {
        "l’internet": "internet",
        "le mot de passe": "salasana",
        "l’ordinateur": "tietokone",
        "la prise": "pistorasia",
        charger: "ladata",
      },
    },
    {
      name: "time",
      list: {
        "le temps": "aika",
        "le jour": "päivä",
        "la semaine": "viikko",
        "le mois": "kuukausi",
        "l’année": "vuosi",
        lundi: "maanantai",
        mardi: "tiistai",
        mercredi: "keskiviikko",
        jeudi: "torstai",
        vendredi: "perjantai",
        samedi: "lauantai",
        dimanche: "sunnuntai",
        "le matin": "aamu",
        "l'après-midi": "iltapäivä",
        "le soir": "illalla",
        "la nuit": "yöllä",
        "plus tôt": "aiemmin",
        "plus tard": "myöhemmin",
        "à l'heure": "ajallaan",
        "en retard": "viivästynyt",
      },
    },
    {
      name: "transport",
      list: {
        "le train": "juna",
        "le bus": "bussi",
        "la voiture": "auto",
        "le tramway": "raitiovaunu",
        "le taxi": "taksi",
        "le vélo": "polkupyörä",
        "à pied": "jalkaisin",
        "la gare": "rautatieasema",
        "la gare routière": "linja-autoasema",
        "la station": "pysäkki",
      },
    },
    {
      name: "verb",
      list: {
        travailler: "työskennellä",
        visiter: "käydä",
        rester: "jäädä",
        "avoir besoin de": "tarvita",
        manger: "syödä",
        demander: "kysyä",
        donner: "antaa",
        aller: "kävellä",
        avoir: "omistaa",
        acheter: "ostaa",
        pouvoir: "voida",
        apprendre: "oppia",
        faire: "tehdä",
        prendre: "ottaa",
        dire: "sanoa",
        écrire: "kirjoittaa",
        voir: "nähdä",
        être: "olla",
        chercher: "etsiä",
        téléphoner: "soittaa",
        boire: "juoda",
        savoir: "tietää",
        vouloir: "haluta",
        payer: "maksaa",
      },
    },
  ],
  "fi-ja": [
    {
      name: "adjective",
      list: {
        いい: "hyvä",
        悪い: "huono",
        新しい: "uusi",
        年取った: "vanha",
        若い: "nuori",
        大きい: "iso",
        小さい: "pieni",
        美しい: "kaunis",
        安い: "halpa",
        高い: "kallis",
        暗い: "tumma",
        明るい: "valoisa",
        一緒に: "yhdessä",
        別に: "erikseen",
        大事: "tärkeä",
        疲れた: "väsynyt",
      },
    },
    {
      name: "color",
      list: {
        色: "väri",
        白い: "valkoinen",
        黒い: "musta",
        赤: "punainen",
        青: "sininen",
        緑: "vihreä",
        黄色: "keltainen",
        カラフル: "värikäs",
      },
    },
    {
      name: "communication",
      list: {
        "こんにちわ！": "Hei!",
        "バイバイ！": "Näkemiin!",
        "ありがとう！": "Kiitos!",
        お願いします: "Ole hyvä!",
        "ごめんなさい！": "Anteeksi!",
        "やめて！": "Seis!",
        "注意！": "Varo!",
        はい: "kyllä",
        いいえ: "ei",
      },
    },
    {
      name: "food",
      list: {
        食べ物: "ruoka",
        レストラン: "ravintola",
        カフェ: "kahvila",
        バー: "baari",
        メニュー: "ruokalista",
        前菜: "alkupala",
        メイン料理: "pääruoka",
        デザート: "jälkiruoka",
        請求書: "lasku",
        ベジタリアン: "kasvissyöjä",
        ビーガン: "vegaani",
        グルテンフリー: "gluteeniton",
        魚: "kala",
        お肉: "liha",
        サラダ: "salaatti",
        牛乳: "maito",
        チーズ: "juusto",
        果物: "hedelmät",
        野菜: "vihannekset",
        ナッツ: "pähkinät",
        コーヒー: "kahvi",
        お茶: "tee",
        水: "vesi",
        ワイン: "viini",
        ビール: "olut",
        ノンアルコール: "alkoholiton",
        "乾杯！": "Kippis!",
      },
    },
    {
      name: "health",
      list: {
        医者: "lääkäri",
        病院: "sairaala",
        薬局: "apteekki",
        痛み止め: "särkylääke",
        薬: "lääke",
        痛み: "kipu",
        背中: "selkä",
        足: "jalka",
        お腹: "vatsa",
        脚: "jalka",
        頭: "pää",
        手: "käsi",
        腕: "käsivarsi",
      },
    },
    {
      name: "numbers",
      list: {
        一: "yksi",
        二: "kaksi",
        三: "kolme",
        四: "neljä",
        五: "viisi",
        六: "kuusi",
        七: "seitsemän",
        八: "kahdeksan",
        九: "yhdeksän",
        十: "kymmenen",
        二十: "kaksikymmentä",
        五十: "viisikymmentä",
        百: "sata",
        千: "tuhat",
      },
    },
    {
      name: "orientation",
      list: {
        方向: "suunta",
        入口: "sisäänkäynti",
        出口: "uloskäynti",
        道り: "katu",
        道: "tie",
        広場: "tori",
        右: "oikealla",
        左: "vasemmalla",
        まっすぐ: "suoraan eteenpäin",
        バック: "takaisin",
      },
    },
    {
      name: "personal-information",
      list: {
        名前: "etunimi",
        苗字: "sukunimi",
        住所: "osoite",
        国: "maa",
        出生地: "syntymäpaikka",
        生年月日: "syntymäaika",
        職業: "ammatti",
        男: "mies",
        女: "nainen",
        成人: "aikuinen",
        子供: "lapsi",
      },
    },
    {
      name: "sentences",
      list: {
        "…なんですか？": "Mikä ...?",
        "…どこですか？": "Missä on ...?",
        "…どうですか？": "Kuinka …?",
        "いくらですか？": "Kuinka paljon se on?",
        "どれくらいかかりますか？": "Kauanko se kestää?",
        "…いますか？": "On ...?",
        "…だといいです": "Haluaisin ...",
        "…必要です": "Tarvitsen ...",
        問題ないです: "Ei ongelmaa!",
      },
    },
    {
      name: "shopping",
      list: {
        スーパー: "supermarketti",
        現金: "raha",
        カード: "pankkikortti",
        かばん: "laukku",
        タンポン: "tamponi",
        生理用ナプキン: "terveysside",
        コンドーム: "kondomi",
        歯磨き: "hammasharja",
        助け: "apua",
        警察: "poliisi",
      },
    },
    {
      name: "surrounding",
      list: {
        家: "talo",
        アパート: "asunto",
        ホテル: "hotelli",
        博物館: "museo",
        ビーチ: "ranta",
        林: "metsä",
        山: "vuori",
        公園: "puisto",
        川: "joki",
        海: "meri",
        湖: "järvi",
        天気: "sää",
        太陽: "aurinko",
        雨: "sade",
        雪: "lumi",
        雷: "ukkonen",
      },
    },
    {
      name: "technology",
      list: {
        インターネット: "internet",
        パスワード: "salasana",
        パソコン: "tietokone",
        コンセント: "pistorasia",
        充電する: "ladata",
      },
    },
    {
      name: "time",
      list: {
        時間: "aika",
        日: "päivä",
        週: "viikko",
        月: "kuukausi",
        年: "vuosi",
        月曜日: "maanantai",
        火曜日: "tiistai",
        水曜日: "keskiviikko",
        木曜日: "torstai",
        金曜日: "perjantai",
        土曜日: "lauantai",
        日曜日: "sunnuntai",
        朝に: "aamu",
        昼に: "iltapäivä",
        夜に: "illalla",
        深夜に: "yöllä",
        "前に/さっき": "aiemmin",
        後で: "myöhemmin",
        時間通りに: "ajallaan",
        遅刻: "viivästynyt",
      },
    },
    {
      name: "transport",
      list: {
        電車: "juna",
        バス: "bussi",
        車: "auto",
        トラム: "raitiovaunu",
        タクシー: "taksi",
        自転車: "polkupyörä",
        歩いて: "jalkaisin",
        電車駅: "rautatieasema",
        バスターミナル: "linja-autoasema",
        駅: "pysäkki",
      },
    },
    {
      name: "verb",
      list: {
        働く: "työskennellä",
        訪れる: "käydä",
        留まる: "jäädä",
        必要: "tarvita",
        食べる: "syödä",
        訪ねる: "kysyä",
        あげる: "antaa",
        行く: "kävellä",
        持っている: "omistaa",
        買う: "ostaa",
        できる: "voida",
        習う: "oppia",
        作る: "tehdä",
        取る: "ottaa",
        言う: "sanoa",
        書く: "kirjoittaa",
        見る: "nähdä",
        となる: "olla",
        探す: "etsiä",
        電話する: "soittaa",
        飲む: "juoda",
        知る: "tietää",
        したい: "haluta",
        払う: "maksaa",
      },
    },
  ],
  "fi-ko": [
    {
      name: "adjective",
      list: {
        좋은: "hyvä",
        나쁜: "huono",
        새로운: "uusi",
        늙은: "vanha",
        젊은: "nuori",
        큰: "iso",
        작은: "pieni",
        아름다운: "kaunis",
        싼: "halpa",
        비싼: "kallis",
        어두운: "tumma",
        밝은: "valoisa",
        같이: "yhdessä",
        분리된: "erikseen",
        중요한: "tärkeä",
        피곤한: "väsynyt",
      },
    },
    {
      name: "color",
      list: {
        색깔: "väri",
        흰색: "valkoinen",
        검정색: "musta",
        빨간색: "punainen",
        파란색: "sininen",
        초록색: "vihreä",
        노란색: "keltainen",
        다채로운: "värikäs",
      },
    },
    {
      name: "communication",
      list: {
        "안녕!": "Hei!",
        "안녕하세요!": "Hei!",
        "안녕히계세요!": "Näkemiin!",
        "감사합니다!": "Kiitos!",
        "…해 주세요.": "Ole hyvä!",
        "죄송합니다!": "Anteeksi!",
        "멈춰!": "Seis!",
        "주의!": "Varo!",
        네: "kyllä",
        아니오: "ei",
      },
    },
    {
      name: "food",
      list: {
        음식: "ruoka",
        식당: "ravintola",
        카페: "kahvila",
        바: "baari",
        메뉴: "ruokalista",
        "전채 요리": "alkupala",
        "주 요리": "pääruoka",
        디저트: "jälkiruoka",
        계산서: "lasku",
        채식: "kasvissyöjä",
        비건: "vegaani",
        "글루텐 없는": "gluteeniton",
        생선: "kala",
        고기: "liha",
        샐러드: "salaatti",
        우유: "maito",
        치즈: "juusto",
        과일: "hedelmät",
        야채: "vihannekset",
        견과류: "pähkinät",
        커피: "kahvi",
        차: "tee",
        물: "vesi",
        와인: "viini",
        맥주: "olut",
        무알콜: "alkoholiton",
        건배: "Kippis!",
      },
    },
    {
      name: "health",
      list: {
        의사: "lääkäri",
        병원: "sairaala",
        약국: "apteekki",
        진통제: "särkylääke",
        약: "lääke",
        고통: "kipu",
        등: "selkä",
        발: "jalka",
        배: "vatsa",
        다리: "jalka",
        머리: "pää",
        손: "käsi",
        팔: "käsivarsi",
      },
    },
    {
      name: "numbers",
      list: {
        "일 / 하나": "yksi",
        "이 / 둘": "kaksi",
        "삼 / 셋": "kolme",
        "사 / 넷": "neljä",
        "오 / 다섯": "viisi",
        "육 / 여섯": "kuusi",
        "칠 / 일곱": "seitsemän",
        "팔 / 여덟": "kahdeksan",
        "구 / 아홉": "yhdeksän",
        "십 / 열": "kymmenen",
        "이십 / 스물": "kaksikymmentä",
        "오십 / 쉰": "viisikymmentä",
        백: "sata",
        천: "tuhat",
      },
    },
    {
      name: "orientation",
      list: {
        방향: "suunta",
        입구: "sisäänkäynti",
        출구: "uloskäynti",
        거리: "katu",
        길: "tie",
        광장: "tori",
        오른쪽: "oikealla",
        왼쪽: "vasemmalla",
        직진: "suoraan eteenpäin",
        뒤로: "takaisin",
      },
    },
    {
      name: "personal-information",
      list: {
        이름: "etunimi",
        성: "sukunimi",
        주소: "osoite",
        나라: "maa",
        출생지: "syntymäpaikka",
        생년월일: "syntymäaika",
        직업: "ammatti",
        남자: "mies",
        여자: "nainen",
        성인: "aikuinen",
        아이: "lapsi",
      },
    },
    {
      name: "sentences",
      list: {
        "… 뭐예요?": "Mikä ...?",
        "… 어디예요?": "Missä on ...?",
        "… 어때요?": "Kuinka …?",
        "… 얼마예요?": "Kuinka paljon se on?",
        "얼마나 걸리나요?": "Kauanko se kestää?",
        "… 있어요?": "On ...?",
        "… 좋겠어요.": "Haluaisin ...",
        "… 필요해요.": "Tarvitsen ...",
        천만에요: "Ei ongelmaa!",
      },
    },
    {
      name: "shopping",
      list: {
        슈퍼마켓: "supermarketti",
        현금: "raha",
        "신용 카드": "pankkikortti",
        가방: "laukku",
        탐폰: "tamponi",
        생리대: "terveysside",
        콘돔: "kondomi",
        칫솔: "hammasharja",
        도움: "apua",
        경찰: "poliisi",
      },
    },
    {
      name: "surrounding",
      list: {
        집: "talo",
        아파트: "asunto",
        호텔: "hotelli",
        박물관: "museo",
        해변: "ranta",
        숲: "metsä",
        산: "vuori",
        공원: "puisto",
        강: "joki",
        바다: "meri",
        호수: "järvi",
        날씨: "sää",
        해: "aurinko",
        비: "sade",
        눈: "lumi",
        뇌우: "ukkonen",
      },
    },
    {
      name: "technology",
      list: {
        인터넷: "internet",
        암호: "salasana",
        컴퓨터: "tietokone",
        콘센트: "pistorasia",
        충전하다: "ladata",
      },
    },
    {
      name: "time",
      list: {
        시간: "aika",
        날: "päivä",
        주: "viikko",
        월: "kuukausi",
        년: "vuosi",
        월요일: "maanantai",
        화요일: "tiistai",
        수요일: "keskiviikko",
        목요일: "torstai",
        금요일: "perjantai",
        토요일: "lauantai",
        일요일: "sunnuntai",
        아침에: "aamu",
        오후에: "iltapäivä",
        저녁에: "illalla",
        밤에: "yöllä",
        이전에: "aiemmin",
        나중에: "myöhemmin",
        정확한: "ajallaan",
        늦어진: "viivästynyt",
      },
    },
    {
      name: "transport",
      list: {
        기차: "juna",
        버스: "bussi",
        자동차: "auto",
        전차: "raitiovaunu",
        택시: "taksi",
        자전거: "polkupyörä",
        걸어서: "jalkaisin",
        기차역: "rautatieasema",
        "버스 터미널": "linja-autoasema",
        정거장: "pysäkki",
      },
    },
    {
      name: "verb",
      list: {
        일하다: "työskennellä",
        방문하다: "käydä",
        남다: "jäädä",
        필요하다: "tarvita",
        먹다: "syödä",
        묻다: "kysyä",
        주다: "antaa",
        가다: "kävellä",
        가지다: "omistaa",
        사다: "ostaa",
        "할수 있다": "voida",
        배우다: "oppia",
        만들다: "tehdä",
        잡다: "ottaa",
        말하다: "sanoa",
        쓰다: "kirjoittaa",
        보다: "nähdä",
        이다: "olla",
        찾다: "etsiä",
        전화하다: "soittaa",
        마시다: "juoda",
        알다: "tietää",
        "…고 싶다": "haluta",
        지불하다: "maksaa",
      },
    },
  ],
  "fi-sk": [
    {
      name: "adjective",
      list: {
        dobré: "hyvä",
        zlé: "huono",
        nove: "uusi",
        staré: "vanha",
        mlade: "nuori",
        veľké: "iso",
        male: "pieni",
        krásne: "kaunis",
        lacné: "halpa",
        drahé: "kallis",
        tmavé: "tumma",
        svetlé: "valoisa",
        spolu: "yhdessä",
        oddelené: "erikseen",
        dôležité: "tärkeä",
        unavené: "väsynyt",
      },
    },
    {
      name: "color",
      list: {
        farba: "väri",
        biela: "valkoinen",
        čierna: "musta",
        červená: "punainen",
        modrá: "sininen",
        zelená: "vihreä",
        žltá: "keltainen",
        farebná: "värikäs",
      },
    },
    {
      name: "communication",
      list: {
        "Ahoj!": "Näkemiin!",
        "Dobrý deň!": "Hei!",
        "Ďakujem!": "Kiitos!",
        "Prosím!": "Ole hyvä!",
        "Prepáč!": "Anteeksi!",
        "Prestaň!": "Seis!",
        "Pozor!": "Varo!",
        áno: "kyllä",
        nie: "ei",
      },
    },
    {
      name: "food",
      list: {
        potraviny: "ruoka",
        reštaurácia: "ravintola",
        kaviareň: "kahvila",
        bar: "baari",
        menu: "ruokalista",
        predjedlo: "alkupala",
        "hlavné jedlo": "pääruoka",
        dezert: "jälkiruoka",
        účet: "lasku",
        vegetariánske: "kasvissyöjä",
        vegánske: "vegaani",
        bezlepkové: "gluteeniton",
        ryba: "kala",
        mäso: "liha",
        šalát: "salaatti",
        mlieko: "maito",
        syr: "juusto",
        ovocie: "hedelmät",
        zelenina: "vihannekset",
        orechy: "pähkinät",
        káva: "kahvi",
        čaj: "tee",
        voda: "vesi",
        víno: "viini",
        pivo: "olut",
        nealkoholické: "alkoholiton",
        "Na zdravie!": "Kippis!",
      },
    },
    {
      name: "health",
      list: {
        "lekár / lekarka": "lääkäri",
        nemocnica: "sairaala",
        lekáreň: "apteekki",
        "liek proti bolesti": "särkylääke",
        liek: "lääke",
        bolesť: "kipu",
        chrbát: "selkä",
        noha: "jalka",
        brucho: "vatsa",
        hlava: "pää",
        ruka: "käsi",
        rameno: "käsivarsi",
      },
    },
    {
      name: "numbers",
      list: {
        jeden: "yksi",
        dva: "kaksi",
        tri: "kolme",
        štyri: "neljä",
        päť: "viisi",
        šesť: "kuusi",
        sedem: "seitsemän",
        osem: "kahdeksan",
        deväť: "yhdeksän",
        desať: "kymmenen",
        dvadsať: "kaksikymmentä",
        päťdesiat: "viisikymmentä",
        sto: "sata",
        tisíc: "tuhat",
      },
    },
    {
      name: "orientation",
      list: {
        smer: "suunta",
        vchod: "sisäänkäynti",
        východ: "uloskäynti",
        ulica: "katu",
        cesta: "tie",
        námestie: "tori",
        vpravo: "oikealla",
        vľavo: "vasemmalla",
        rovno: "suoraan eteenpäin",
        späť: "takaisin",
      },
    },
    {
      name: "personal-information",
      list: {
        "(krstné) meno": "etunimi",
        priezvisko: "sukunimi",
        adresa: "osoite",
        krajina: "maa",
        rodisko: "syntymäpaikka",
        "dátum narodenia": "syntymäaika",
        povolanie: "ammatti",
        muž: "mies",
        žena: "nainen",
        "dospelý/a": "aikuinen",
        dieťa: "lapsi",
      },
    },
    {
      name: "sentences",
      list: {
        "Kde...?": "Mikä ...?",
        "Kde je ...?": "Missä on ...?",
        "Ako …?": "Kuinka …?",
        "Koľko?": "Kuinka paljon se on?",
        "Ako dlho?": "Kauanko se kestää?",
        "Je tam ...?": "On ...?",
        "Prosím si ...": "Haluaisin ...",
        "Potrebujem ...": "Tarvitsen ...",
        "V pohodě!": "Ei ongelmaa!",
      },
    },
    {
      name: "shopping",
      list: {
        supermarket: "supermarketti",
        peniaze: "raha",
        "banková karta": "pankkikortti",
        taška: "laukku",
        tampón: "tamponi",
        vložka: "terveysside",
        kondóm: "kondomi",
        "zubná kefka": "hammasharja",
        pomoc: "apua",
        polícia: "poliisi",
      },
    },
    {
      name: "surrounding",
      list: {
        dom: "talo",
        byt: "asunto",
        hotel: "hotelli",
        múzeum: "museo",
        pláž: "ranta",
        les: "metsä",
        vrch: "vuori",
        park: "puisto",
        rieka: "joki",
        more: "meri",
        jazero: "järvi",
        počasie: "sää",
        slnko: "aurinko",
        dážď: "sade",
        sneh: "lumi",
        búrka: "ukkonen",
      },
    },
    {
      name: "technology",
      list: {
        internet: "internet",
        heslo: "salasana",
        počítač: "tietokone",
        zásuvka: "pistorasia",
        nabiť: "ladata",
      },
    },
    {
      name: "time",
      list: {
        čas: "aika",
        deň: "päivä",
        týždeň: "viikko",
        mesiac: "kuukausi",
        rok: "vuosi",
        Pondelok: "maanantai",
        Utorok: "tiistai",
        Streda: "keskiviikko",
        Štvrtok: "torstai",
        Piatok: "perjantai",
        Sobota: "lauantai",
        Nedeľa: "sunnuntai",
        ráno: "aamu",
        popoludnie: "iltapäivä",
        večer: "illalla",
        "v noci": "yöllä",
        skôr: "aiemmin",
        neskôr: "myöhemmin",
        "v čase": "ajallaan",
        mešká: "viivästynyt",
      },
    },
    {
      name: "transport",
      list: {
        vlak: "juna",
        autobus: "bussi",
        auto: "auto",
        električka: "raitiovaunu",
        taxík: "taksi",
        bicykel: "polkupyörä",
        pešo: "jalkaisin",
        "železničná stanica": "rautatieasema",
        "autobusová stanica": "linja-autoasema",
        zastávka: "pysäkki",
      },
    },
    {
      name: "verb",
      list: {
        pracovať: "työskennellä",
        navštíviť: "käydä",
        zostať: "jäädä",
        potrebovať: "tarvita",
        jesť: "syödä",
        "pýtať sa": "kysyä",
        dať: "antaa",
        ísť: "kävellä",
        mať: "omistaa",
        kúpiť: "ostaa",
        môcť: "voida",
        "naučiť sa": "oppia",
        vyrobiť: "tehdä",
        vziať: "ottaa",
        povedať: "sanoa",
        písať: "kirjoittaa",
        vidieť: "nähdä",
        byť: "olla",
        hľadať: "etsiä",
        telefonovať: "soittaa",
        piť: "juoda",
        vedieť: "tietää",
        chcieť: "haluta",
        platiť: "maksaa",
      },
    },
  ],
};
