export type Encounter = typeof encounter[number];

export const encounter = [
  {
    encounter: "Helpful",
    minor: [20],
    moderate: [20],
    major: null,
    extreme: null,
  },
  {
    encounter: "Harmless",
    minor: [18, 19],
    moderate: [18, 19],
    major: [20],
    extreme: null,
  },
  {
    encounter: "Environment",
    minor: [14, 15, 16, 17],
    moderate: [14, 15, 16, 17],
    major: [18, 19],
    extreme: [19, 20],
  },
  {
    encounter: "Nothing",
    minor: [6, 7, 8, 9, 10, 11, 12, 13],
    moderate: [8, 9, 10, 11, 12, 13],
    major: [14, 15, 16, 17],
    extreme: [17, 18],
  },
  {
    encounter: "Easy combat",
    minor: [2, 3, 4, 5],
    moderate: [4, 5, 6, 7],
    major: [8, 9, 10, 11, 12, 13],
    extreme: [13, 14, 15, 16],
  },
  {
    encounter: "Average combat",
    minor: [1],
    moderate: [2, 3],
    major: [4, 5, 6, 7],
    extreme: [7, 8, 9, 10, 11, 12],
  },
  {
    encounter: "Challenging combat",
    minor: null,
    moderate: [1],
    major: [2, 3],
    extreme: [3, 4, 5, 6],
  },
  {
    encounter: "Hard combat",
    minor: null,
    moderate: null,
    major: [1],
    extreme: [1, 2],
  },
];
