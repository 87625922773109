export const danger = {
  starting: {
    easy: "3 or less",
    average: "4-15",
    challenging: "16-30",
    hard: "31 or more",
    max: "25",
  },
  novice: {
    easy: "10 or less",
    average: "11-30",
    challenging: "31-50",
    hard: "50 or more",
    max: "100",
  },
  expert: {
    easy: "30 or less",
    average: "31-50",
    challenging: "51-125",
    hard: "126 or more",
    max: "250",
  },
  master: {
    easy: "50 or less",
    average: "51-125",
    challenging: "126-200",
    hard: "201 or more",
    max: "None",
  },
};

export const levels = Object.keys(danger) as Array<keyof typeof danger>;
