export const ftdMonsters = [
  {
    hd: 0.25,
    hp: 1,
    weak: -2,
    normal: 2,
    strong: 2,
    damage: "1d4",
  },
  {
    hd: 0.5,
    hp: 2,
    weak: -2,
    normal: 2,
    strong: 2,
    damage: "1d6",
  },
  {
    hd: 1,
    hp: 5,
    weak: -2,
    normal: 2,
    strong: 3,
    damage: "1d8",
  },
  {
    hd: 2,
    hp: 9,
    weak: -1,
    normal: 3,
    strong: 4,
    damage: "1d10",
  },
  {
    hd: 3,
    hp: 13,
    weak: -1,
    normal: 3,
    strong: 5,
    damage: "1d12",
  },
  {
    hd: 4,
    hp: 17,
    weak: 0,
    normal: 4,
    strong: 6,
    damage: "2d6",
  },
  {
    hd: 5,
    hp: 21,
    weak: 0,
    normal: 4,
    strong: 7,
    damage: "2d8",
  },
  {
    hd: 6,
    hp: 25,
    weak: 1,
    normal: 5,
    strong: 8,
    damage: "2d10",
  },
  {
    hd: 7,
    hp: 29,
    weak: 1,
    normal: 5,
    strong: 9,
    damage: "2d12",
  },
  {
    hd: 8,
    hp: 33,
    weak: 2,
    normal: 6,
    strong: 10,
    damage: "3d8",
  },
  {
    hd: 9,
    hp: 37,
    weak: 2,
    normal: 6,
    strong: 11,
    damage: "3d8+1",
  },
  {
    hd: 10,
    hp: 41,
    weak: 3,
    normal: 7,
    strong: 12,
    damage: "3d8+2",
  },
  {
    hd: 11,
    hp: 45,
    weak: 3,
    normal: 7,
    strong: 12,
    damage: "3d10",
  },
  {
    hd: 12,
    hp: 49,
    weak: 4,
    normal: 8,
    strong: 12,
    damage: "3d10+1",
  },
  {
    hd: 13,
    hp: 53,
    weak: 4,
    normal: 8,
    strong: 12,
    damage: "3d10+2",
  },
  {
    hd: 14,
    hp: 57,
    weak: 5,
    normal: 9,
    strong: 12,
    damage: "3d12",
  },
  {
    hd: 15,
    hp: 61,
    weak: 5,
    normal: 9,
    strong: 12,
    damage: "3d12+1",
  },
  {
    hd: 16,
    hp: 65,
    weak: 6,
    normal: 10,
    strong: 12,
    damage: "3d12+2",
  },
  {
    hd: 17,
    hp: 69,
    weak: 6,
    normal: 10,
    strong: 12,
    damage: "4d10",
  },
  {
    hd: 18,
    hp: 73,
    weak: 7,
    normal: 10,
    strong: 12,
    damage: "4d10+1",
  },
];
